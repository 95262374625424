import logo from '../../assets/evelandMediaLogo.svg';
import './header.css';
import Socialmedia from "../socialMedia/Socialmedia";
const Header = () => {
    return (
        <header>
            <img className="logo" src={logo} alt="evelandmedia Logo" />
            <Socialmedia />
        </header>
    )
}

export default Header;