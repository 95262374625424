import React from "react";
import { useTranslation } from 'react-i18next';
import './home.css';

const Home = ()  => {
    const [backgroundSize, setBackgroundSize] = React.useState(window.innerHeight);
    const [showScrollDown, setShowScrollDown] = React.useState(true);

    const { t } = useTranslation();

    window.addEventListener("resize", (event) => {
        setBackgroundSize(window.innerHeight);
    });

    window.addEventListener("scroll", (event) => {
        if (window.scrollY >= 100 && showScrollDown) {
            setShowScrollDown(false);
        }
        else if (window.scrollY < 100 && !showScrollDown) {
            setShowScrollDown(true);
        }
    });

    return (
        <div className="content homeContent" style={{minHeight: `${backgroundSize}px`}}>
            <div className="holder">
                <blockquote cite="Frei nach Werner Mitsch">
                    <span dangerouslySetInnerHTML={{__html: t("home.quote")}} />
                    <small>– Werner Mitsch</small>
                </blockquote>
            </div>
            { showScrollDown && (
                <svg className="scrollDown" viewBox="0 0 26 26"><g><polygon fill="#C70842" points="0.046,2.582 2.13,0.498 12.967,11.334 23.803,0.498 25.887,2.582 12.967,15.502  "/><polygon
                fill="#C70842" points="0.046,13.582 2.13,11.498 12.967,22.334 23.803,11.498 25.887,13.582 12.967,26.502  "/></g></svg>
            )}
        </div>
    )
}



export default Home;