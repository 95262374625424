import React from "react";
import './photography.css';
import ImageGallery from "react-image-gallery";
import {useTranslation} from "react-i18next";

const categories = [
    {
        name: 'nature',
        numImages: 20
    },
    {
        name: 'animals',
        numImages: 24
    },
    {
        name: 'cologne',
        numImages: 22
    },
    {
        name: 'street',
        numImages: 29
    },
    {
        name: 'others',
        numImages: 21
    }
];

const images = {};

categories.forEach(cat => {
    images[cat.name] = [];
    for (let num = 1; num <= cat.numImages; num++) {
        images[cat.name].push({
            original: require(`../../assets/gallery/${cat.name}/original/${cat.name}_${num}.jpg`),
            thumbnail: require(`../../assets/gallery/${cat.name}/thumb/${cat.name}_thumb_${num}.jpg`),
        });
    }
});

const Photography = () => {
    const [backgroundSize, setBackgroundSize] = React.useState(window.innerHeight - 75);
    const [selectedTab, setSelectedTab] = React.useState('nature');
    const { t } = useTranslation();

    window.addEventListener("resize", (event) => {
        setBackgroundSize(window.innerHeight - 105);
    });

    return (
        <div className="content photographyContent" style={{minHeight: `${backgroundSize}px`}}>
            <hr className="separator" />
            <h2 dangerouslySetInnerHTML={{__html: t("photography.headline")}} />
            <ul className="tabs">
                {Object.keys(images).map(tab => (
                    <li key={tab} className={'tab' + (selectedTab === tab ? ' selected' : '')} onClick={() => {
                        setSelectedTab(tab);
                    }}>{t('photography.' + tab)}</li>
                ))}
            </ul>
            <ImageGallery items={images[selectedTab]} fullscreen={true} style={{width: '100%'}}/>
        </div>
    )
}

export default Photography;