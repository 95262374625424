import React from "react";
import './welcome.css';
import {useTranslation} from "react-i18next";

const Welcome = ()  => {

    const { t } = useTranslation();

    return (
        <div className="welcome">
            <h2 dangerouslySetInnerHTML={{__html: t("welcome.headline")}} />
            <p className="welcomeText" dangerouslySetInnerHTML={{__html: t("welcome.welcomeText")}} />
        </div>
    )
}

export default Welcome;