import './footer.css';
import {useTranslation} from "react-i18next";
import React from "react";

const Footer = () => {
    const { i18n } = useTranslation();
    const { t } = useTranslation();

    return (
        <div className="footer">
            <h3 dangerouslySetInnerHTML={{__html: t("footer.headline")}} />
            <p>
                Evi Baumgartner<br />
                Gut Capellen<br />
                53913 Swisttal<br />
                02254 - 84 72 38<br />
                evi@evelandmedia.de
            </p>
            <p dangerouslySetInnerHTML={{__html: t("footer.cookie")}} />
            <hr className="separator" />
            <div className="language">
                {i18n.language === 'de-DE' ? (
                    <button className="button" onClick={() => i18n.changeLanguage('en-EN')}>Switch to English</button>
                ) : (
                    <button className="button" onClick={() => i18n.changeLanguage('de-DE')}>Wechseln zu Deutsch</button>
                )}
            </div>
        </div>
    )
}

export default Footer;