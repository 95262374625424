import React from "react";
import './contact.css';
import {useTranslation} from "react-i18next";

const Contact = () => {
    const [backgroundSize, setBackgroundSize] = React.useState(window.innerHeight - 105);

    window.addEventListener("resize", (event) => {
        setBackgroundSize(window.innerHeight - 75);
    });

    const { t } = useTranslation();

    return (
        <div className="content contactContent" style={{minHeight: `${backgroundSize}px`}}>
            <h2 dangerouslySetInnerHTML={{__html: t("contact.headline")}} />
            <p dangerouslySetInnerHTML={{__html: t("contact.content")}} />
            <p><a href="mailto:evi@evelandmedia.de" className="button big" dangerouslySetInnerHTML={{__html: t("contact.button")}} /></p>
        </div>
    )
}

export default Contact;