import './socialmedia.css';
import { useTranslation } from 'react-i18next';

const Socialmedia = () => {
    const { t } = useTranslation();

    return (
        <div className="socialmedia-container">
            <a href="https://instagram.com/evelandmedia" className="instagram">{t("socialmedia.instagram")}</a>
            <a href="https://www.facebook.com/Baumgartner.Film.Foto/" className="facebook">{t("socialmedia.facebook")}</a>
            <a href="https://vimeo.com/user7843695" className="vimeo">{t("socialmedia.vimeo")}</a>
        </div>
    )
}

export default Socialmedia;