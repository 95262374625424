import React from "react";
import './about.css';
import {useTranslation} from "react-i18next";

const About = ()  => {
    const [backgroundSize, setBackgroundSize] = React.useState(window.innerHeight - 105);

    const { t } = useTranslation();

    window.addEventListener("resize", (event) => {
        setBackgroundSize(window.innerHeight - 105);
    });

    return (
        <>
            <div className="aboutme-container" style={{minHeight: `${backgroundSize}px`}}>
                <div className="aboutme-image"></div>
                <div className="aboutme-content">
                    <div>
                        <h2 className="about-headline" dangerouslySetInnerHTML={{__html: t("about.headline")}} />
                    </div>
                    <div className="content" style={{ textAlign: 'left'}} dangerouslySetInnerHTML={{__html: t("about.text")}} />
                </div>
            </div>
            <hr className="separator" />
        </>
    )
}

export default About;