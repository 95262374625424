const resources = {
    de: {
        translation: {
            home: {
                quote: "Fische, die nicht fliegen,<br /> kommen nie auf einen grünen Zweig."
            },
            welcome: {
                headline: "Wenn...",
                welcomeText: "<p>...ich es mit meinen Fotografien schaffe, dass du zwei Mal hinschaust, dann siehst Du, wofür ich brenne.</p>"
            },
            about: {
                headline: "Über mich.",
                text: `<p>…lasse ich gerne meine Fotos und Filme sprechen. <br />
                        „Wenn ich es mit meinen Fotografien schaffe, dass du zwei Mal hinschaust, dann siehst Du, wofür ich brenne“.<br />
                        Während in meinen Filmen viele Bilder eine Geschichte erzählen, liebe ich die Herausforderung in der Fotografie mit nur einem einzigen Bild eine Geschichte zu erzählen.<br />
                        Die offene und leidenschaftliche Einstellung zur Fotografie gibt mir die Freiheit mit dem Herzen zu fotografieren und mich in meinen Fotos uneingeschränkt ausdrücken zu können.<br /> 
                        Mein tägliches Credo, und das nicht nur in der Fotografie, ist: <br />
                        „Du musst die Ente aufs Wasser setzen, um zu sehen ob sie schwimmt.“</p>`
            },
            photography: {
                headline: 'Fotografie.',
                text: 'Hier möchte ich ein paar Fotomomente mit euch teilen.',
                buy: `Sie können meine Fotos auch auf <strong>Pictress.de</strong> kaufen:`,
                buybutton: 'Fotos kaufen',
                nature: 'Natur',
                dogs: 'Hunde',
                lostPlaces: 'Verlassene Orte',
                sport: 'Sport',
                citys: 'Städte',
                streetart: 'Streetfotografie',
                people: 'Menschen',
                animals: 'Tiere',
                others: 'Sonstiges',
                cologne: 'Köln',
                street: 'Street',
            },
            videoproduction: {
                headline: 'Videografie.',
                text: `Neben Familiendokumentationen hat es mir im Besonderen die Hunde- bzw Tierfilmdokumentationen angetan.`,
                button: 'Video ansehen',
                title01: `<strong>Tier Dokumentationen</strong> <br /> Impressionen Hunde Shooting <br /> mit “Mo”`,
                title02: `<strong>Dokumentationen</strong> <br /> Heimerzheim Flut 2021 <br /> Ein Dorf will sich erinnern`,
                title03: `<strong>Familienfilme</strong> <br /> Portrait der <br /> Familie Harpering`
            },
            contact: {
                headline: 'Kontakt.',
                content: 'Wenn ihr Lust habt, gemeinsam mit mir auf die Fotopirsch zu gehen, dann schreibt mir gerne eine Email. ',
                button: 'E-Mail schreiben'
            },
            footer: {
                headline: 'Verantwortlich für den Inhalt',
                cookie: '🍪 Cookies und Kaffee gibt es vielleicht bei einem persönlichen Gespräch mit mir, aber nicht auf meiner Webseite.  🍪'
            },
            socialmedia: {
                instagram: 'evelandmedia bei Instagram',
                facebook: 'evelandmedia bei Facebook',
                vimeo: 'evelandmedia bei Vimeo',
            }
        }
    },
    en: {
        translation: {
            home: {
                quote: "Fish that don't fly<br /> won't get on the green track."
            },
            welcome: {
                headline: "If...",
                welcomeText: "... I manage to make you look twice with my photographs, then you'll see what I'm passionate about.",
            },
            about: {
                headline: "About me.",
                text: `<p>…I like to let my photos and films do the talking.<br />
                            “If I can make you look twice with my photographs, then you’ll see what I’m passionate about.”<br />
                            While many images in my films tell a story, I love the challenge of telling a story with just a single image in photography.<br />
                            The open and passionate attitude towards photography gives me the freedom to photograph with my heart and to be able to express myself without restriction in my photos.<br />
                            My daily credo, and not only in photography, is:<br />
                            "You have to put the duck on the water to see if it swims."</p>`,
            },
            photography: {
                headline: 'Photography.',
                text: 'Here I want to share a few moments with you.',
                buy: `You can buy my pictures on <strong>pictress.com:</strong>`,
                buybutton: 'Buy pictures',
                nature: 'nature',
                dogs: 'dogs',
                lostPlaces: 'lost places',
                sport: 'sport',
                citys: 'citys',
                streetart: 'Streetart',
                people: 'people',
                animals: 'animals',
                others: 'other',
                cologne: 'cologne',
                street: 'street',
            },
            videoproduction: {
                headline: 'Videography.',
                text: `My second passion is videography. <br /> I was particularly impressed by the dog and animal videography.`,
                button: 'Watch video',
                title01: `<strong>Animal Documentations</strong><br />Impression Dog Shooting <br /> with “Mo”`,
                title02: `<strong>Documentations</strong><br />Heimerzheim flood 2021 <br /> A village wants to remember`,
                title03: `<strong>Family Movies</strong><br />Portrait of <br /> Family Harpering`
            },
            contact: {
                headline: 'Contact.',
                content: 'Get in touch if you would like to go on a photo hunt with me.',
                button: 'Send E-Mail'
            },
            footer: {
                headline: 'Responsible for the content',
                cookie: '🍪 There are maybe cookies and coffee at a Meeting with me, but never on my website. 🍪'
            },
            socialmedia: {
                instagram: 'evelandmedia at Instagram',
                facebook: 'evelandmedia at Facebook',
                vimeo: 'evelandmedia at Vimeo',
            }
        }
    }
};

export default resources;