import React from "react";
import './videoProduction.css';
import videoteaserDoku from '../../assets/videoteaser-doku.jpg';
import videoteaserFamilie from '../../assets/videoteaser-familienfilm.jpg';
import videoteaserTier from '../../assets/videoteaser-tierfilm.jpg';
import {useTranslation} from "react-i18next";

const VideoProduction = () => {
    const [backgroundSize, setBackgroundSize] = React.useState(window.innerHeight - 105);

    window.addEventListener("resize", (event) => {
        setBackgroundSize(window.innerHeight - 75);
    });

    const { t } = useTranslation();

    return (
        <div className="content videoProductionContent" style={{minHeight: `${backgroundSize}px`}}>
            <hr className="separator" />
            <h2 dangerouslySetInnerHTML={{__html: t("videoproduction.headline")}} />
            <ul className="teaserList">
                <li>
                    <a href="https://vimeo.com/749488485" target="_blank" rel="noreferrer">
                        <img src={videoteaserTier} alt="" id="videoteaserTier"/>
                        <label htmlFor="videoteaserTier" dangerouslySetInnerHTML={{__html: t("videoproduction.title01")}} />
                    </a>
                    <a href="https://vimeo.com/749488485" className="button" target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{__html: t("videoproduction.button")}} />
                </li>
                <li>
                    <a href="https://vimeo.com/750705761" target="_blank" rel="noreferrer">
                        <img src={videoteaserDoku} alt="" id="videoteaserDoku" />
                        <label htmlFor="videoteaserDoku" dangerouslySetInnerHTML={{__html: t("videoproduction.title02")}} />
                    </a>
                    <a href="https://vimeo.com/750705761" className="button" target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{__html: t("videoproduction.button")}} />
                </li>
                <li>
                    <a href="https://vimeo.com/355957769" target="_blank" rel="noreferrer">
                        <img src={videoteaserFamilie} alt="" id="videoteaserFamilie"/>
                        <label htmlFor="videoteaserFamilie" dangerouslySetInnerHTML={{__html: t("videoproduction.title03")}} />
                    </a>
                    <a href="https://vimeo.com/355957769" className="button" target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{__html: t("videoproduction.button")}} />
                </li>
            </ul>
            <hr className="separator" />
        </div>
    )
}

export default VideoProduction;