import './App.css';
import Header from './components/header/Header';
import Home from './components/home/Home';
import Welcome from "./components/welcome/Welcome";
import About from "./components/about/About";
import VideoProduction from "./components/videoProduction/videoProduction";
import Footer from "./components/footer/Footer"
import Photography from "./components/photography/photography";
import Contact from "./components/contact/contact";
import './i18n';

function App() {
  return (
    <div className="App">
      <div>
        <Header />
        <Home />
        <Welcome />
        <Photography />
        <VideoProduction />
        <About />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;
